<template>
  <v-form>
    <v-card max-width="800px"
            class="mt-4 mx-auto"
            :loading="loading"
            :disabled="loading"
    >
      <v-card-title> Profil</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-select
                :items="salutationItems"
                :error-messages="salutationErrors"
                :label="$t('salutation')"
                @blur="$v.item.salutation.$touch()"
                @input="$v.item.salutation.$touch()"
                v-model="item.salutation"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="titleErrors"
                :label="$t('title')"
                @blur="$v.item.title.$touch()"
                @input="$v.item.title.$touch()"
                v-model="item.title"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="firstNameErrors"
                :label="$t('firstName')"
                @blur="$v.item.firstName.$touch()"
                @input="$v.item.firstName.$touch()"
                required
                v-model="item.firstName"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="lastNameErrors"
                :label="$t('lastName')"
                @blur="$v.item.lastName.$touch()"
                @input="$v.item.lastName.$touch()"
                required
                v-model="item.lastName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="companyErrors"
                :label="$t('company')"
                @blur="$v.item.company.$touch()"
                @input="$v.item.company.$touch()"
                v-model="item.company"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" md="6">
              <v-file-input
                :rules="imageRules"
                :label="$t('profileImage')"
                :placeholder="$t('profileImagePlaceholder')"
                v-model="uploadImage"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
              ></v-file-input>
            </v-col>
            <v-col cols="4" md="6">
              <v-avatar v-if="item.image && getDocumentItem(item.image, 'contentUrl')" size="90">
                <v-img :src="documentEntryPoint + getDocumentItem(item.image, 'contentUrl')"></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-auto" color="primary" @click="submitItem">{{ $t('Submit') }}</v-btn>
        <v-btn class="ml-sm-2 mainAccent lighten-3" dark @click="resetItem">{{ $t('Reset') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {validationMixin} from 'vuelidate';
import has from 'lodash/has';
import {required} from 'vuelidate/lib/validators';
import DocumentMixin from "@/mixins/DocumentMixin";

export default {
  name: 'ProfileForm',
  mixins: [validationMixin, DocumentMixin],
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {
      }
    },

    loading: {
      type: Boolean,
      required: true
    },

    initialValues: {
      type: Object,
      default: () => {
      }
    },

    handleSubmit: {
      type: Function,
      required: false
    },

    handleReset: {
      type: Function,
      required: false
    },
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      company: null,
      salutation: null,
      title: null,
      image: null,
      uploadImage: null,
      salutationItems: ['Frau', 'Herr', 'Divers'],
      imageRules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
    };
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    firstNameErrors() {
      const errors = [];

      if (!this.$v.item.firstName.$dirty) {
        return errors;
      }

      has(this.violations, 'firstName') && errors.push(this.violations.firstName);

      !this.$v.item.firstName.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    lastNameErrors() {
      const errors = [];

      if (!this.$v.item.lastName.$dirty) {
        return errors;
      }

      has(this.violations, 'lastName') && errors.push(this.violations.lastName);

      !this.$v.item.lastName.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    companyErrors() {
      const errors = [];

      if (!this.$v.item.company.$dirty) {
        return errors;
      }
      has(this.violations, 'company') && errors.push(this.violations.company);

      return errors;
    },
    salutationErrors() {
      const errors = [];

      if (!this.$v.item.salutation.$dirty) {
        return errors;
      }
      has(this.violations, 'salutation') && errors.push(this.violations.salutation);

      return errors;
    },
    titleErrors() {
      const errors = [];

      if (!this.$v.item.title.$dirty) {
        return errors;
      }
      has(this.violations, 'title') && errors.push(this.violations.title);

      return errors;
    },
    violations() {
      return this.errors || {};
    }
  },
  methods: {
    log(messasge) {
      console.log(messasge);
    },
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    }
  },
  validations: {
    item: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      company: {},
      salutation: {},
      title: {},
      image: {},
      uploadImage: {}
    }
  },
};
</script>

<template>
  <div>
    <ProfileForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      :handle-reset="resetForm"
      :handle-submit="onUpdateProfileForm"
      :loading="isLoading"
      v-if="item"
    />
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import ProfileForm from '../../components/profile/Form.vue';
import Loading from '../../components/Loading';
import UpdateMixin from '../../mixins/UpdateMixin';
import DocumentMixin from '../../mixins/DocumentMixin';

const servicePrefix = 'Profile';

export default {
  name: 'ProfileUpdate',
  servicePrefix,
  mixins: [UpdateMixin, DocumentMixin],
  data() {
    return {
      updateMessage: this.$t('profileUpdateSuccess'),
      updateRoute: null
    };
  },
  components: {
    Loading,
    ProfileForm
  },
  computed: {
    ...mapFields('profile', {
      isLoading: 'isLoading',
      error: 'error',
      updated: 'updated',
      violations: 'violations'
    }),
    ...mapGetters('profile', ['find'])
  },
  methods: {
    ...mapActions('profile', {
      retrieve: 'load',
      update: 'update',
    }),
    afterUpdate() {
      this.$store.commit('authentication/profileDataSuccess', this.updated);
    },
    onUpdateProfileForm() {
      if (this.isValidForm() && this.$refs.updateForm.uploadImage) {
        this.uploadDocument(this.$refs.updateForm.uploadImage);
        return;
      }
      this.onSendForm();
    }
  },
  updated() {
    if (!this.updated) {
      return;
    }
    this.$store.commit('authentication/profileDataSuccess', this.updated);
  },
  watch: {
    item() {
      this.updateRoute = {
        name: `${this.$options.servicePrefix}Show`,
        params: {id: this.item['@id']}
      }
    },
    uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      this.item.image = this.uploadedDocument['@id'];
      this.$store.commit('authentication/profileImageSuccess', this.uploadedDocument.documentUrl);
      this.onSendForm();
    }
  },
}
</script>
